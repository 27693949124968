<template>
  <div class="relative group" :style="{ 'min-height': `calc(${mapHeight}px + 2rem)` }">
    <div
      v-if="showInline"
      class="flex justify-between absolute top-0 left-0 z-20 opacity-0 group-hover:opacity-100 h-full w-14 bg-gradient-to-r from-gray-50 to-transparent"
    >
      <fw-button type="link-muted" icon="arrow-left" @click.native="scrollCalendar('left')"></fw-button>
    </div>
    <div ref="parent" class="overflow-x-auto no-scrollbar relative">
      <div
        ref="yearmap"
        class="flex gap-2"
        :class="{ 'flex-nowrap': showInline, 'flex-wrap': !showInline }"
        :style="{ 'min-height': mapHeight + 'px' }"
      ></div>
      <div v-if="distinctIcons?.length" class="hidden">
        <component :is="'fw-icon-' + icon" v-for="(icon, index) in distinctIcons" :id="icon" :key="index"></component>
      </div>
    </div>
    <div id="daysLabel" ref="daysLabel" class="text-center min-h-6 w-full text-gray-500 text-sm"></div>
    <div
      v-if="showInline"
      class="flex justify-between absolute top-0 right-0 z-20 opacity-0 group-hover:opacity-100 h-full w-14 bg-gradient-to-l from-gray-50 to-transparent"
    >
      <fw-button type="link-muted" icon="arrow-right" @click.native="scrollCalendar('right')"></fw-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockYearActivity',
  props: {
    maxValue: {
      type: Number,
      default: 10
    },
    year: {
      type: Number,
      default: () => new Date().getFullYear()
    },
    /**
     * Ex:
     * { '01/01/2024': 1, '02/01/2024': 2, '05/01/2024': 4 }
     */
    data: {
      type: Object,
      default: () => {}
    },
    /**
     * Ex:
     * { '25/12/2024': 'text-yellow-500' }
     */
    customClass: {
      type: Object,
      default: () => {}
    },
    customIcons: {
      type: Object,
      default: () => {}
    },
    christmasTree: {
      type: Boolean,
      default: true
    },
    showToday: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: false
    },
    showInline: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    },
    expand: {
      type: String,
      default: 'auto' //auto or horizontal
    },
    weekendColor: {
      type: String,
      default: 'bg-gray-300'
    },
    scrollToToday: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      mapHeight: 100,
      monthsLabels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      weekDays: ['2ª', '3ª', '4ª', '5ª', '6ª', 'S', 'D'],
      colWidth: 12,
      lineHeigth: 12
    }
  },

  computed: {
    distinctIcons() {
      return this.customIcons ? Array.from(new Set(Object.values(this.customIcons))) : []
    }
  },

  watch: {
    mapHeight() {
      this.$refs.yearmap.innerHTML = ''
      this.generateMap()
    }
  },

  mounted() {
    this.generateMap()
  },

  methods: {
    generateMap() {
      //get available space
      let width = this.$refs.parent.offsetWidth
      let minWidth = 750
      this.colWidth = 12
      this.lineHeigth = 12
      let gapSize = 1.5
      if (this.expandable && width > minWidth) {
        //expand the map
        let ratio = width / minWidth
        this.colWidth = this.colWidth * ratio
        if (this.expand == 'auto') {
          this.lineHeigth = this.lineHeigth * ratio
        }
        gapSize = gapSize * ratio
      }

      this.mapHeight = this.lineHeigth * 8 + gapSize * 5

      let row = 0

      if (this.showInline) {
        const ghostMonth = document.createElement('div')
        ghostMonth.style.minWidth = '40px'
        this.$refs.yearmap.appendChild(ghostMonth)
      }

      // Lop through months
      for (let month = 0; month <= 11; month++) {
        row = 0

        let monthDiv = document.createElement('div')
        monthDiv.classList.add('relative')
        monthDiv.setAttribute('data-label', this.monthsLabels[month])
        monthDiv.setAttribute('id', `month-${month}`)
        monthDiv.style.minWidth = this.colWidth * 7 + gapSize * 7 + 'px'
        monthDiv.style.height = this.mapHeight + 'px'
        this.$refs.yearmap.appendChild(monthDiv)

        let monthLabel = this.createMonthLabel(month)
        monthDiv.appendChild(monthLabel)

        // Loop through days
        for (let day = 1; day <= 31; day++) {
          let date = `${this.zeroPad(day)}/${this.zeroPad(month + 1)}/${this.year}`
          if (!this.isDateValid(date)) continue

          let jsDate = new Date(this.year, month, day)
          // let weekDay = new Date(this.year, month, day).getDay() // 0 - 6
          let weekDay = (jsDate.getDay() + 6) % 7 // A

          // Get days positions
          let x = weekDay * this.colWidth + weekDay * gapSize
          let y = 16 + gapSize + row * this.lineHeigth + gapSize * row

          const square = this.createSquare(date, weekDay, x, y)
          monthDiv.appendChild(square)

          // If sunday move to next row (week)
          if (weekDay == 6) row++
        }
      }

      if (this.showInline) {
        const ghostMonth2 = document.createElement('div')
        ghostMonth2.style.minWidth = '40px'
        this.$refs.yearmap.appendChild(ghostMonth2)
      }

      if (this.scrollToToday) {
        document
          .getElementById(`month-${new Date().getMonth()}`)
          .scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },

    createMonthLabel(month) {
      let monthLabel = document.createElement('div')
      // monthLabel.classList.add('absolute')
      monthLabel.classList.add('h-4')
      monthLabel.classList.add('text-xs')
      monthLabel.classList.add('text-gray-500')
      monthLabel.innerHTML = this.monthsLabels[month]
      return monthLabel
    },

    createSquare(date, weekDay, x, y) {
      let square = document.createElement('div')
      square.classList.add('absolute')
      square.style.width = this.colWidth + 'px'
      square.style.height = this.lineHeigth + 'px'
      square.classList.add('rounded')
      let value = this.data[date] ? this.data[date] : 0
      let percent = parseInt(value) > 0 ? value / this.maxValue : 0

      let opacity = 'bg-opacity-80'
      let color = 'bg-gray-200'
      if (this.christmasTree) {
        if (percent > 0 && percent <= 0.33) {
          color = 'bg-green-400'
        } else if (percent > 0.33 && percent <= 0.66) {
          color = 'bg-yellow-400'
        } else if (percent > 0.66 && percent <= 1) {
          color = 'bg-red-400'
        } else if (percent > 1) {
          color = 'bg-red-700'
        }
      } else {
        //shades of green
        color = 'bg-gray-200'
        // opacity = 'bg-opacity-20'

        if (percent > 0) {
          color = 'bg-green-500'
        }
        // if (percent > 0 && percent <= 0.33) {
        // opacity = 'bg-opacity-20'
        // } else if (percent > 0.33 && percent <= 0.66) {
        // opacity = 'bg-opacity-50'
        // } else if (percent > 0.66 && percent <= 1) {
        // opacity = 'bg-opacity-90'
        // }
      }

      if ((weekDay == 5 || weekDay == 6) && !value) {
        color = this.weekendColor
      }

      if (this.customIcons && date in this.customIcons) {
        const icon = document.getElementById(this.customIcons[date]).cloneNode(true)
        icon.removeAttribute('id')
        icon.style.width = this.colWidth - 2 + 'px'
        icon.style.height = this.lineHeigth - 2 + 'px'
        icon.classList.add(color)
        icon.classList.add(opacity)
        square.appendChild(icon)
        square.classList.add('flex', 'justify-center', 'items-center')
      }

      square.classList.add(color)
      square.classList.add(opacity)
      square.classList.add('ring-inset')
      square.classList.add('hover:ring-1')
      square.classList.add('hover:ring-gray-300')

      let isToday = new Date().toLocaleDateString('pt-PT') == date
      if (isToday && this.showToday) {
        square.classList.add('ring-2')
        square.classList.add('ring-blue-500')
        square.classList.add('ring-opacity-50')
      } else {
        if (this.border) {
          square.classList.add('ring-1')
          square.classList.add('ring-black')
          square.classList.add('ring-opacity-5')
        }
      }

      if (this.customClass && date in this.customClass) {
        const parts = this.customClass[date].split(' ')
        if (parts.length > 1) {
          square.classList.add(...parts)
        } else {
          square.classList.add(this.customClass[date])
        }
      }

      square.setAttribute('data-percent', percent)
      square.setAttribute('data-value', this.data[date] ?? 0)
      square.setAttribute('data-label', `${date}`)
      square.onmouseover = () => {
        if (this.data[date]) {
          this.$refs.daysLabel.innerHTML = `${date} | ${this.data[date]}`
        } else {
          this.$refs.daysLabel.innerHTML = `${date}`
        }
      }

      square.onmouseleave = () => {
        this.$refs.daysLabel.innerHTML = ''
      }
      square.style.left = x + 'px'
      square.style.top = y + 'px'
      return square
    },

    isDateValid(dateStr) {
      // Split the input string into day, month, and year
      const [day, month, year] = dateStr.split('/').map(Number)

      // Create a Date object (months are 0-indexed in JavaScript, so subtract 1 from the month)
      const date = new Date(year, month - 1, day)

      // Check if the parsed date matches the input values
      return (
        date instanceof Date &&
        !isNaN(date) &&
        date.getFullYear() === year &&
        date.getMonth() + 1 === month && // Months are 0-indexed, so add 1
        date.getDate() === day
      )
    },

    zeroPad(num, places = 2) {
      return String(num).padStart(places, '0')
    },

    scrollCalendar(position) {
      this.$refs.parent.scrollBy({
        left: position === 'left' ? -200 : 200,
        behavior: 'smooth'
      })
      console.log(this.$refs.parent.scrollLeft)
    }
  }
}
</script>
